import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
// import Header from '../components/Header';
import SideFooter from '../components/SideFooter';

import CatalogTable from '../components/CatalogTable';
import CatalogFull from '../components/CatalogFull';

import Scroll from '../components/Scroll';


import { Link } from 'gatsby';
const IndexPage = () => (
    <Layout>
        <div id="wrapper">
            <section className="intro">
                {/* <section> */}
                {/* <span className="image fill" data-position="center">
       <img src={heroImage} alt="" />
      </span> */}
                <div className="content">
                    <h1>Formations</h1>
                    <h2>Catalogue de formations 2020</h2>
                    {/* </div>
     <div className="content"> */}
                    <h3 id="des-labs-devops">Des labs DevOps pour gagner en autonomie</h3>
                    <p>
                        Encourager l’expérimentation systématique dans les conditions réalistes du DevOps : des “labs” au centre de la formation professionnelle pour assurer un apprentissage pertinent, opérationnel et captivant. <br />
                        Basées sur des travaux pratiques documentés et reproductibles dans des environnement complexes, nos formations permettent d’éviter les explications simplistes, déconnectées et la transmission verticale.
                    </p>
                    <p>
                        <a href="https://supports.uptime-formation.fr/" className="button primary large">
                            Supports
                        </a>   <Link to="/catalogue.pdf" className="button primary large">Catalogue</Link>
                    </p>
                    <p><Scroll type="id" element="first">
                        <a href="#first" className="arrow">
                            <span className="label">Next</span>
                        </a>
                    </Scroll>
                    </p>
                    {/* <Link to={catalogue_pdf} className="button primary large">Télécharger le catalogue au format PDF</Link> */}
                </div>

            </section>

            <section>
                <header id="first"><h2>Catalogue de formations 2020</h2></header>
                <CatalogTable />
            </section>

            <CatalogFull />

            <section>
                <header>
                    <h2 id="nous-contacter">Nous contacter</h2>
                    <SideFooter />

                </header>
                <div className="content">
                    <p><Link to="formations/#nous-contacter">Contactez-nous</Link> pour obtenir le détail des programmes, ou encore pour concevoir une formation sur mesure avec vous !</p>
                    <ul className="actions">
                        <li>
                            <Link to="/" className="button primary large">
                                Découvrir Uptime
                            </Link>
                        </li>
                    </ul>
                    <h3>Découvrez les formateurs d'Uptime</h3>
                    <p><Link to="/about" className="button primary large">Qui sommes-nous ?</Link></p>
                </div>
            </section>

            <Footer />
        </div>
    </Layout >
);

export default IndexPage;
