import React from 'react';
import config from '../../config';
import { Link } from 'gatsby';

import docker from '../assets/images/docker.png';
// import ansible from '../assets/images/ansible.webp';
import ansible from '../assets/images/ansible-k8s.webp';
import terraform from '../assets/images/terraform.png';
import kubernetes from '../assets/images/k3s.jpg';
import securite from '../assets/images/securite.jpg';
// import ContactForm from '../components/ContactForm';

const support_ansible = 'https://supports.uptime-formation.fr/06-ansible/'
const support_docker = 'https://supports.uptime-formation.fr/04-docker/'
// const support_jenkins = 'https://cursus-janvier2020.uptime-formation.fr/04-jenkins/'
const support_k8s = 'https://supports.uptime-formation.fr/05-kubernetes/'



export default function CatalogFull() {
    return (<>
        {/* <section><header></header>
    <div className="content"><h2>Catalogue détaillé</h2></div></section> */}
        <section>

            {/* - Introduction à Docker et Docker avancé
- Introduction à Ansible et Ansible avancé avec AWX/Ansible-Tower
- Kubernetes et Rancher
- Cloud avancé avec Terraform et AWS (ou pourrait potentiellement adapter pour Azure et AKS j'ai regardé rapidement ça a l'air faisable) 
- CI/CD avec Gitlab-CI et Jenkins
- Mise en place d'un SIEM (stack ELK)
- Hardening de sécurité d'un serveur Linux
- Python / JS / GNU/Linux et Bash / Rust / C / Elixir

+ audits de sécurité */}


            <header>
                <h1 id="DevOps">DevOps</h1>
                <p>
                    Maîtriser les outils et les paradigmes du mouvement DevOps.
                </p>
                <header>
                </header>
                <h2 id="docker">Docker</h2>
                <p>
                    Maîtriser Docker et assimiler le principe de la conteneurisation, des microservices et de l'Infrastructure-as-Code.
                </p>
            </header>
            <div className="content">
                <span className="image main">
                    {/* <img src={docker} alt="" /> */}
                </span>
                <h3>Introduction à Docker - 2 jours (14h) - 1000€ HT</h3>
                <p>
                    Apprendre à conteneuriser une app, créer des images et les inscrire dans un registry. Manipuler des conteneurs et les faire interagir au sein d'une architecture microservices avec Docker Compose et créer un cluster avec Docker Swarm.
                    Comprendre les concepts de conteneurs et de l’architecture micro-services. Etre capable de déployer des applications complexes interagissant ensemble grâce à Docker et ses outils.
                    {/* Comprendre l’utilité et les cas d’usage de Docker, Docker Compose et Swarm.
Savoir manipuler images, conteneurs, registries, réseaux virtuels, volumes, services et stacks.
- Présentation de Docker : principe, fonctionnalités, contexte
- Principes et architecture : historique de la conteneurisation, LXC par rapport aux VM, composants Unix (Cgroups, namespaces), isolation des ressources et systèmes de fichiers en "union mount"
- L'écosystème Docker : Docker Machine, Docker Compose, Kitematic, Portainer, Docker Swarm, Docker Hub
- Gestion des images et des conteneurs en ligne de commande
- Utilisation de Dockerfile pour créer de images : principales instructions, bonnes pratiques d'écriture de Dockerfile, utilisation d'un registry
- Volumes de données : bind mounting et volumes nommés
- Implémentation des réseaux virtuels dans Docker et réseaux overlay
- Applications multi-conteneurs avec Compose : éléments et utilisation du Compose file
- Clustering avec Swarm et présentation de Kubernetes
- Problématiques de sécurité propres à Docker et aux conteneurs */}
                </p>
                <p><a href={support_docker} className="button primary large" >Consulter le support du module Docker</a></p>
                <h3>Docker avancé avec Podman et Buildah - 1 jour - 600 € HT</h3>
                <p>
                    Maîtriser les subtilités de l'architecture Docker et de la conteneurisation. Gestion des applications stateful et des bases de données distribuées. Manipulation des drivers de volume, de réseau et de logs pour étendre les possibilités de Docker. Contraintes d'accès au disque et au réseau et optimisation. Découverte de nouvelles briques logicielles pour pallier les carences de Docker-compose et Swarm. Introduction à Kubernetes.
                </p>
            </div>
        </section>
        <section>
            <header>
                <h2 id="ansible">Ansible</h2>
                <p>
                    Ansible est le couteau suisse de l'administration d'infrastructures. Il implémente le concept d'idempotence de l'Infrastructure-as-Code dans des contextes extrêmement versatiles et ne requiert que SSH et Python pour exécuter ses modules.
                </p>
            </header>
            <div className="content">
                <span className="image main">
                    <img src={ansible} alt="" />
                </span>
                <h3>Introduction à Ansible - 3 jours (21h) - 1500€ HT</h3>
                <p>Organiser un dépôt de code permettant le déploiement et l'orchestration d'applications dans une architecture complexe grâce à l’environnement de scripting Ansible. Configurer et manipuler les playbooks, inventaires et rôles Ansible. Bonnes pratiques pour l'écriture de rôles Ansible.
                </p>
                <h3>Ansible avancé avec AWX et Ansible-Tower - 2 jours (14h) - 1200€ HT</h3>
                <p>Orchestration et déploiement d'applications complexes (déploiement continu, load-balancing et haute disponibilité). Installation et usage d'AWX et Ansible-Tower. Utilisation conjointe d'Ansible avec les autres technologies <em>Cloud</em> (Docker, Kubernetes…), de CI (Jenkins, Gitlab-CI) et fournisseurs d'infrastructure (Terraform, AWS, DigitalOceal). Utilisation avancée des inventaires dynamiques. Bonnes pratiques d'écriture de nouveaux modules.</p>
                <p><a href={support_ansible} className="button primary large" >Consulter le support des modules Ansible</a></p>
            </div>
        </section>
        <section>
            <header>

                <h2 id="kubernetes">Kubernetes</h2>
                <p>
                    Kubernetes permet d'utiliser les technologies de conteneurisation en maîtrisant l'orchestration de façon fine et en intégrant une gamme d'outils avancés pour le clustering.
                </p>
            </header>
            <div className="content">
                <span className="image main">
                    <img src={kubernetes} alt="" />
                </span>
                <h3>Introduction à Kubernetes - 2 jours (14h) - 1200€ HT</h3>
                <p>
                    Manipulations autour des concepts qui composent Kubernetes : pods, services, namespaces, équivalents entre Docker/Docker Compose et Kubernetes. Découverte des contrôleurs et de l'interface de gestion de Kubernetes.
                </p>
                <p><a href={support_k8s} className="button primary large" >Consulter le support des modules K8S</a></p>

                <h3>Kubernetes avancé et découverte de Rancher - 2 jours (14h) - 1200€ HT</h3>
                <p>
                    Travaux pratiques documentés. Gestion des réseaux et volumes et briques de Kubernetes (Ingress, Operators...). Concepts et scénarios de conteneurisation et de virtualisation avancée.
                </p>
            </div>
        </section>
        <section>
            <header>
                <h2 id="terraform">Terraform</h2>
                <p>
                    Terraform est une technologie créée pour manipuler les infrastructures Cloud (AWS, Google Cloud Platform, OVH, Scaleway, etc.) selon les principes de l'Infrastructure-as-Code.
                </p>
            </header>
            <div className="content">
                <span className="image main">
                    <img src={terraform} alt="" />
                </span>
                <h3>Cloud avancé avec Terraform et AWS - 2 jours (14h) - 1200€ HT</h3>
                <p>
                    Création d'environnements réseau complexes sur le cloud AWS et exploration et configuration des ressources offertes par AWS avec Terraform. Modélisation de Virtual Private Cloud (VPC) via l'API d'AWS et le provider Terraform.
                </p>
            </div>
        </section>
        <section>
            <header>
                <h2 id="jenkins">Jenkins</h2>
                <p>
                    Concepts de CI/CD, méthodologie, outils et concepts du DevOps à travers la manipulation de Jenkins.
                </p>
            </header>
            <div className="content">
                <span className="image main">
                    {/* <img src={jenkins} alt="" /> */}
                </span>
                <h3>CI/CD avec Jenkins et Ansible - 2 jours (21h) - 1200€ HT</h3>
                <p>Concepts de CI/CD, méthodologie, outils et concepts du DevOps avec Jenkins et Ansible.</p>
                {/* <p><a href={support_jenkins} className="button primary large" >Consulter le support des modules Jenkins</a></p> */}
            </div>
        </section>

        <section>
            <header>
                <h1 id="languages">Langages</h1>
                <p>Python - JavaScript - TypeScript - GNU/Linux - Bash - C - Elixir</p>
            </header>
            <div className="content">
                <span className="image main">
                    {/* <img src={languages} alt="" /> */}
                </span>
                <h3>Introduction à Python - 3 jours (21h) - 1500€ HT</h3>
                <p>Introduction à Python : de la syntaxe de base à la logique de l'orienté objet, animée par un contributeur actif d'un logiciel libre en Python.</p>
                <h3>Introduction à JavaScript - 3 jours (21h) - 1500€ HT</h3>
                <p>Introduction à JavaScript selon la norme ECMAScript 8 : une approche saine et concise, focalisée sur les meilleures pratiques, qui permet de mettre en lumière les principaux écueils du langage.
                </p>
                <h3>Introduction à TypeScript - 3 jours (21h) - 1500€ HT</h3>
                <p>Introduction au langage TypeScript : du développement web de façon maîtrisée et raisonnable.</p>
                <h3>Introduction au C / C avancé - 3 jours (21h) - 1500€ HT</h3>
                <p>Introduction ou perfectiomment au langage C en norme ANSI (C11 ou C18), pour une écriture de code C de qualité. Éviter les failles mémoires avec des outils de fiabilité logicielle. Formation pratique avec des explications théoriques au fil de l'eau et sur demande des apprenant·es.
                </p>
                <h3>Introduction à GNU/Linux et Bash - 3 jours (21h) - 1500€ HT</h3>
                <p>Découverte de la ligne de commande avec Bash et de l'administration système sous GNU/Linux : des fondamentaux à la gestion d'un serveur.
                </p>
                {/* <h3>Introduction à Rust - 3 jours (21h) - 1500€ HT</h3>
                <p>Introduction au langage Rust, découverte des particularités du langage : <em>ownership</em>, <em>borrowing</em>, <em>move semantics</em>. Face-à-face pédagogique au travers d'exemples concrets entrecoupés d'explications théoriques. En fin de formation, un mini-projet d'application sera réalisé avec les stagiaires.</p> */}
                <h3>Introduction à Elixir et Phoenix - 2 jours (14h) - 1200€ HT</h3>
                <p>Introduction à Elixir, un langage de programmation moderne et fonctionnel basé sur la machine Erlang, et initiation au framework web Phoenix.</p>
            </div>

        </section>
        <section>
            <header>
                <h1 id="securite">Sécurité</h1>
                <p>
                    Concevoir un modèle de menaces. Comprendre les différents types d’attaque et connaître les remédiations correspondantes. Développer des moyens de détection et de sécurisation système et réseau à l’aide d’outils configurables.
                </p>
            </header>
            <div className="content">
                <span className="image main">
                    <img src={securite} alt="" />
                </span>
                <h3>Introduction à la sécurité informatique - 2 jours (14h) - 1000€ HT</h3>
                <p>
                    Concevoir un modèle de menaces. Comprendre les différents types d’attaque et connaître les remédiations correspondantes. Développer des moyens de détection et de sécurisation système et réseau à l’aide d’outils configurables. Connaître le profil des attaquants et paysage de la sécurité dans les entreprises françaises, le cycle d'une attaque et les vecteurs d'attaque. Simulation réaliste de phishing, attaques Web, attaques réseau, failles logicielles et post-exploitation, grâce à l'outil Metasploit. Notions de cryptographie. Sécurité dans les développements logiciel. Cadres légaux et organisationnels : normes, frameworks.
                </p>
                <h3>Mise en place d'un SIEM (stack ELK) - 2 jours (14h) - 1200€ HT</h3>
                <p>
                    Développer des moyens de détection et de sécurisation système et réseau à l’aide d’outils configurables : la suite Elastic (ELK) et Wazuh (OSSEC) avec Docker et Ansible.</p>
                <h3>Hardening de sécurité d'un serveur Linux - 1 jour - 600€ HT</h3>
                <p>Comprendre les différents types d’attaque et connaître les remédiations correspondantes. Développer des moyens de détection et de sécurisation système et réseau à l’aide d’outils configurables.</p>
                <h3>Méthodes avancées de l'analyse forensique et de la réponse à incident - 3 jours (21h) - 1800€ HT</h3>
                <p>
                    Présentation, explications et utilisation des techniques de <em>forensic</em> sur des systèmes Windows et Linux à travers bases théoriques et exercices pratiques (fonctionnement des systèmes de fichiers, analyse de trames réseau…).
                    Elaboration de méthodologie de réponse à incident et d'analyse forensique illustrée par des études de cas. Exploration du cadre légal de l'analyse forensique et de la réponse à incident en France.
                </p>
            </div>
        </section>
    </>);
}
