import React from 'react';
import config from '../../config';
import { Link } from 'gatsby';

export default function CatalogTable() {
    return (
        <>
            <header></header>
            <div className="content">
                <h2>Thématiques</h2>
                <h3>Technologies</h3>
                <ul className="feature-icons">

                    <li className="icon ansible"><Link to="/formations#ansible">Ansible</Link></li>
                    <li className="icon fa-docker"><Link to="/formations#docker">Docker</Link></li>
                    <li className="icon fa-jenkins"><Link to="/formations#ansible">Jenkins</Link></li>
                    <li className="icon kubernetes"><Link to="/formations#kubernetes">Kubernetes</Link></li>
                    <li className="icon terraform"><Link to="/formations#terraform">Terraform</Link></li>
                    <li className="icon fa-gitlab"><Link to="/formations#topics">Gitlab-CI</Link></li>
                    <li className="icon elasticsearch"><Link to="/formations#topics">Stack Elastic (ELK)</Link></li>
                    <li className="icon fa-code"><Link to="/formations#topics">Unix/Linux et Bash</Link></li>
                    <li className="icon fa-lock"><Link to="/formations#security">Sécurité informatique</Link></li>
                    <li className="icon machine-learning"><Link to="/formations#topics">Machine Learning</Link></li>
                </ul>
                <h3>Langages</h3>
                <ul className="feature-icons">
                    <li className="icon fa-phoenix-framework"><Link to="/formations#languages">Elixir/Phoenix</Link></li>
                    {/* <li className="icon fa-java"><Link to="/formations#languages">Java</Link></li> */}
                    <li className="icon fa-js"><Link to="/formations#languages">JavaScript</Link></li>
                    <li className="icon fa-python"><Link to="/formations#languages">Python</Link></li>
                    {/* <li className="icon rust"><Link to="/formations#languages">Rust</Link></li> */}
                </ul></div>
        </>
    );
}
